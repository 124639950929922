






















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Location } from 'vue-router';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Concentrations from 'common-modules/src/store/Concentrations';
import { AttendanceGrade, ScoreGrade, Submission } from '@/store/interface/Grade';
import jwlTaskMeta from '@/components/jwlTaskMeta.vue';
import TaskIcon from '@/components/TaskIcon.vue';
import JwlButton from '@/components/JwlButton.vue';
import BackBar from '@/components/BackBar.vue';
import { RunTask } from '@/store/interface/Task';

const JwlGradeAction = () => import('@/components/jwlGradeAction.vue');
const JwlGradeView = () => import('@/components/jwlGradeView.vue');
const CommonError = () => import('common-modules/src/components/CommonError.vue');
const LmsCourseDocument = () => import('@/components/LmsCourseDocument.vue');
const jwlUndoPublish = () => import('@/components/jwlUndoPublish.vue');
const jwlStudentSwitch = () => import('@/components/jwlStudentSwitch.vue');
const QuizFacilitatorReview = () => import('@/components/quiz/QuizFacilitatorReview.vue');
const QuizStudentReview = () => import('@/components/quiz/QuizStudentReview.vue');

@Component({
  components: {
    QuizStudentReview,
    QuizFacilitatorReview,
    JwlButton,
    JwlGradeAction,
    JwlGradeView,
    jwlStudentSwitch,
    jwlUndoPublish,
    CommonError,
    jwlTaskMeta,
    LmsCourseDocument,
    TaskIcon,
    BackBar,
  },
  computed: mapGetters([
    'baseConcentrations',
  ]),
})
export default class JwlGrade extends Vue {
  baseConcentrations!: Concentrations;

  loaded = false;
  score = 0;
  comment = '';
  submission: Submission | null = null;
  task: RunTask | null = null;
  error: string | null = null;

  @Watch('$route')
  fetchData (): void {
    this.loaded = false;
    const { course, task, lmsId } = this.$route.params;
    this.$store.dispatch('getData', `gradebook/${course}/task/${task}/${lmsId}`)
      .then((data) => {
        this.task = data.task;
        this.submission = data.submission;
        if (this.submission?.grade?.date) {
          this.comment = this.submission.grade.comment || '';
          this.score = this.submission.grade.score || 0;
        }
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }

  localizedDate (dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleString();
  }

  get gradebookRoute (): Location {
    const { task, lmsId, course } = this.$route.params;
    const baseConcentration = this.baseConcentrations.dataAsFlatArray().find((bc) => bc.courses.find((bcCourse) => bcCourse.code === course));
    return {
      name: 'class',
      params: {
        concentration: baseConcentration?.code || '',
      },
      query: {
        focusCourse: course,
        focusTask: task,
        focusUser: lmsId,
      },
    };
  }

  handleGradePublished (grade: ScoreGrade | AttendanceGrade): void {
    if (this.submission) {
      this.submission.grade = grade;
    }
  }

  get showDescription (): boolean {
    return !!this.task && this.task.type !== 'quiz';
  }

  get showQuizForFacilitator (): boolean {
    return !!this.task && this.task.type === 'quiz' && this.isGradableAndAllowed;
  }

  get showQuizForStudent (): boolean {
    return !!this.task && this.task.type === 'quiz' && this.task.role === 'student' && !!this.submission?.grade?.date;
  }

  get scores (): number[] {
    const options = [];

    if (this.task?.maxScore) {
      for (let option = 0; option <= this.task.maxScore; option += 1) {
        options.push(option);
      }
    }

    return options;
  }

  get gradeThemeClass (): Record<string, boolean> {
    return {
      'jwl-grade--bachelor': this.task?.programmeType === 'bachelor',
      'jwl-grade--professional': this.task?.programmeType === 'professional',
      'jwl-grade--teacher-training': this.task?.programmeType === 'teacher-training',
      'jwl-grade--academy': this.task?.programmeType === 'academy',
    };
  }

  get isAllowedToSwitchStudents (): boolean {
    if (!this.task) {
      return false;
    }
    const { task } = this;
    const isOnlineAndResponsible = task.role === 'online_facilitator' && ['online_facilitator', 'online-onsite_facilitator'].includes(task.responsibility);
    const isOnsiteAndResponsible = task.role === 'onsite_facilitator' && ['onsite_facilitator', 'onsite-online_facilitator'].includes(task.responsibility);
    return task.type !== 'discuss'
      && (task.role === 'admin'
        || (isOnsiteAndResponsible || isOnlineAndResponsible)
      );
  }

  get isGradableAndAllowed (): boolean {
    const { submission, task } = this;

    if (!task) {
      return false;
    }

    const isOnlineAndResponsible = task.role === 'online_facilitator' && ['online_facilitator', 'online-onsite_facilitator'].includes(task.responsibility);
    const isOnsiteAndResponsible = task.role === 'onsite_facilitator' && ['onsite_facilitator', 'onsite-online_facilitator'].includes(task.responsibility);
    if (!submission || task.role === 'student' || (task.role !== 'admin' && !isOnlineAndResponsible && !isOnsiteAndResponsible)) {
      return false;
    }
    let isPublished = !!submission.publishedDate || !!submission.publishedAt;
    if (['track', 'discuss', 'attendance'].includes(task.type)) {
      isPublished = true;
    }

    return isPublished;
  }

  get isStudent (): boolean {
    return this.task?.role === 'student';
  }

  get undoPublishAllowed (): boolean {
    if (this.isStudent || !this.task) {
      return false;
    }
    const { task, submission } = this;
    const isOnlineAndResponsible = task.role === 'online_facilitator' && ['online_facilitator', 'online-onsite_facilitator'].includes(task.responsibility);
    const isOnsiteAndResponsible = task.role === 'onsite_facilitator' && ['onsite_facilitator', 'onsite-online_facilitator'].includes(task.responsibility);
    const isAdmin = task.role === 'admin';

    return !!submission?.grade?.date
      && (isAdmin || isOnlineAndResponsible || isOnsiteAndResponsible);
  }

  created (): void {
    this.fetchData();
  }
}
